:root {
  --yellow: #FFE900;
  --gris: #808080;
}

.DefaultTwoHHorizontal {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.DefaultTwoHVertical {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.DefaultColsModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
}

.ModalConfirm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: white;
  height: 30vh;
  width: 50vw;
  border: 5px solid black;
  border-radius: 15px;
  z-index: 2000;
  align-items: center;
  box-sizing: border-box;
  margin-top: 35vh;
  margin-left: 25vw;
}

.ModalConfirm h1 {
  grid-row: 1;
  grid-column: 1 / span 5;
  font-size: x-large !important;
  color: black !important;
  font-weight: bolder !important;
  text-align: center !important;
}

.ModalConfirm h2 {
  grid-row: 2;
  grid-column: 1 / span 5;
  font-size: medium !important;
  color: var(--gris) !important;
  font-weight: bold !important;
  text-align: center !important;
}

.ModalConfirmYes {
  grid-row: 3;
  grid-column: 4;
  width: 100% !important;
  border-radius: 20px;
  padding: 10px;
  background-color: var(--yellow) !important;
  color: black !important;
  font-size: medium !important;
  font-weight: bold !important;
}

.ModalConfirmNo {
  grid-row: 3;
  grid-column: 2;
  width: 100% !important;
  border-radius: 20px;
  padding: 10px;
  background-color: white !important;
  color: black !important;
  font-size: medium !important;
  font-weight: bold !important;
}

.ModalConfirm button:hover {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  display: inline-flex;
  flex-direction: column;
  background-color: white;
  height: 80vh;
  width: 90vw;
  border: 5px solid black;
  border-radius: 15px;
  z-index: 1001;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10vh;
  margin-left: 5vw;
}

.modal-content h1 {
  font-size: x-large;
  color: var(--gris);
  font-weight: bolder;
  text-align: center;
}

.EditarTasa {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.EditarTasa h1 {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarTasa h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarTasa input {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.EditarTasa button {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.EditarTasa button:hover {
  cursor: pointer;
}

.EditarTopes {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.EditarTopes h1 {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarTopes h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarTopes input {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.EditarTopes button {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.EditarTopes button:hover {
  cursor: pointer;
}

.idhover {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.idhover:hover {
  cursor: pointer;
  color: #FFE900;
}

.VerCliente {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
}

.VerCliente h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.VerCliente h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: end;
  margin-left: 10px;
}

.VerCliente img {
  border-radius: 10px;
  align-self: center;
  padding: 10px;
  max-height: 100px;
  max-width: 100px;
}

.VerCliente button {
  width: 10%;
  border-radius: 20px;
  padding: 2%;
  margin: 2%;
  background-color: black;
}

.VerCliente button:hover {
  cursor: pointer;
}

.RechazarSolicitud {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.RechazarSolicitud h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.RechazarSolicitud h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: end;
  margin-left: 10px;
}

.RechazarSolicitud button {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.RechazarSolicitud button:hover {
  cursor: pointer;
}

.VerDocumentos {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 0.1fr 1fr;
  align-items: flex-start;
  justify-content: space-evenly;
  overflow: auto;
}

.VerDocumentosCol {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.VerDocumentos p {
  grid-column: 1 / span 2;
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: start;
  align-self: center;
  text-align: center;
}

.VerDocumentos h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.VerDocumentos h2 {
  font-size: medium;
  font-weight: bold;
  color: black;
  text-align: end;
  margin-left: 10px;
}

.VerDocumentos a {
  font-size: medium;
  font-weight: bold;
  color: black;
  text-align: end;
  margin-left: 10px;
  text-decoration: none;
}

.VerDocumentos a:hover {
  text-decoration: underline;
  color: var(--yellow);
  cursor: pointer;
  font-weight: 900;
}

.VerDocumentos button {
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  padding: 5px;
}

.VerDocumentos button:hover {
  cursor: pointer;
}

.AprobarSolicitud {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.AprobarSolicitud h1 {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.AprobarSolicitud h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: center;
}

.AprobarSolicitud input {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.AprobarSolicitud button {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.AprobarSolicitud button:hover {
  cursor: pointer;
}

.CompletarSolicitud {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.CompletarSolicitud h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.CompletarSolicitud h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: end;
  margin-left: 10px;
}

.CompletarSolicitud input {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
}

.CompletarSolicitud button {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.CompletarSolicitud button:hover {
  cursor: pointer;
}

.CrearCarro {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
}

.CrearCarro h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.CrearCarro h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: end;
}

.CrearCarro h3 {
  font-size: small;
  color: black;
  font-weight: bold;
  text-align: center;
}

.CrearCarro input {
  width: 80%;
  border-radius: 20px;
  padding: 2%;
  font-size: smaller;
  font-weight: normal;
  text-align: center;
}

.CrearCarro button {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
  margin-top: 3%;
}

.CrearCarro button:hover {
  cursor: pointer;
}

.CrearCarroCheckbox {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CrearCarroCheckbox input {
  margin-left: 100px;
  transform: scale(1.5);
}

.SubirPDF {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-top: 20px;
  overflow-y: auto;
}

.SubirPDF input {
  background-color: var(--yellow);
  font-weight: bold;
  font-size: small;
  margin-bottom: 20px;
}

.SubirPDF p {
  margin-top: 10px;
  color: var(--gris);
  font-size: medium;
  font-weight: bold;
}

.EditarCredito {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.EditarCredito h1 {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarCredito h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarCredito input {
  width: 50%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.EditarCredito button {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.EditarCredito button:hover {
  cursor: pointer;
}

.VerCuotas {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.VerCuotasP {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: start;
  align-self: center;
  text-align: center;
}

.VerCuotas h1 {
  font-size: large;
  color: var(--gris);
  font-weight: bold;
  text-align: start;
}

.VerCuotas h2 {
  font-size: medium;
  font-weight: bold;
  color: black;
  text-align: end;
  margin-left: 10px;
}

.VerCuotas button {
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  padding: 5px;
}

.VerCuotas button:hover {
  cursor: pointer;
}

.EditarCuota {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.EditarCuota h1 {
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarCuota h2 {
  font-size: medium;
  color: black;
  font-weight: bold;
  text-align: center;
}

.EditarCuota input {
  width: 100%;
  border-radius: 20px;
  padding: 2%;
  font-size: medium;
  font-weight: bold;
  text-align: center;
}

.EditarCuota button {
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
  margin: 10px;
}

.EditarCuota button:hover {
  cursor: pointer;
}

.EditarCuotaDate {
  width: 100% !important;
  background-color: var(--yellow);
}

.react-datepicker__day--selected {
  background-color: var(--yellow) !important;
  color: black !important;
  font-weight: bolder;
}

.EditarCuotaSelect {
  width: 100% !important;
  font-size: medium;
  font-weight: bold;
}

.VerMensajes {
  width: 100%;
  height: 70%;
  background-color: white;
  grid-template-columns: 0.8fr 0.2fr;
  grid-template-rows: 0.1fr 0.8fr 0.1fr;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.VerMensajes h1 {
  grid-row: 1;
  grid-column: 1 / span 2;
  font-size: large;
  color: black;
  font-weight: bold;
  text-align: center;
}

.VerMensajes h3 {
  font-size: smaller;
  color: var(--gris);
  font-weight: normal;
  text-align: center;
}

.VerMensajesMsj {
  height: 100%;
  width: 100%;
  grid-row: 2;
  grid-column: 1 / span 2;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.VerMensajesIn {
  font-size: small;
  background-color: var(--yellow);
  color: black;
  font-weight: normal;
  text-align: start;
  border-radius: 20px;
  align-self: flex-start;
}

.VerMensajesOut {
  font-size: small;
  background-color: #DDDDDD;
  color: black;
  font-weight: normal;
  text-align: end;
  border-radius: 20px;
  align-self: flex-end;
}

.VerMensajes input {
  grid-row: 3;
  grid-column: 1;
  border-radius: 20px;
  width: 90%;
  padding: 2%;
  font-size: small;
  font-weight: normal;
  text-align: center;
}

.VerMensajes button {
  grid-row: 3;
  grid-column: 2;
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  padding: 5px;
}

.VerMensajes button:hover {
  cursor: pointer;
}