:root {
  --yellow: #FFE900;
  --gris: #808080;
}

.DefaultRows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.DefaultCols {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.AdminPanel {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Dashboard {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 20%;
  width: 100%;
}

.Dashboard h1 {
  font-size: large;
  font-weight: bold;
  padding: 10px;
}

.Dashboard h2 {
  font-size: medium;
  font-weight: bold;
  padding: 10px;
}

.Dashboard h3 {
  font-size: small;
  font-weight: bold;
  padding: 10px;
}

.Dashboard button {
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.Dashboard button:hover {
  cursor: pointer;
}

.DashboardApp {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
  width: 25%;
  overflow-x: auto;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.DashboardBD {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
  width: 25%;
  overflow-x: auto;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.DashboardTasas {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 90%;
  width: 25%;
  overflow-y: auto;
  overflow-x: auto;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.DashboardTopes {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 90%;
  width: 25%;
  overflow-y: auto;
  overflow-x: auto;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.Buttons {
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 10%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.Buttons button {
  background-color: white;
  color: black;
  height: 100%;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 2px solid black;
  font-size: large;
  font-weight: bolder;
}

.Buttons button.active {
  background-color: var(--yellow);
  border: none;
}

.Buttons button:hover {
  background-color: var(--gris);
  cursor: pointer;
}

.SearchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--yellow);
  height: 10%;
  width: 100%;
}

.SearchBar input {
  height: 50%;
  width: 1000px;
  background-color: var(--yellow);
  padding: 10px;
  font-size: medium;
  font-weight: bold;
  margin: 10px;
  border-radius: 20px;
  border: 2px solid black;
  box-sizing: border-box;
}

.SearchBar button {
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  padding: 5px;
}

.SearchBar button:hover {
  cursor: pointer;
}

.List {
  background-color: var(--yellow);
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  overflow-y: auto;
}

.List button {
  background-color: var(--yellow);
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  align-self: center;
  padding: 5px;
}

.List button:hover {
  cursor: pointer;
}

.List img {
  border-radius: 10px;
  align-self: center;
  padding: 10px;
  max-height: 50%;
}

.ListItem {
  background-color: white;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 2px solid black;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
}

.ListItem h1 {
  font-size: small;
  font-weight: bold;
  color: var(--gris);
  text-align: center;
}

.ListItem h2 {
  font-size: medium;
  font-weight: normal;
  color: black;
  text-align: center;
  padding-right: 10px;
  width: 150px;
}

.ListItem a {
  font-size: medium;
  font-weight: normal;
  color: black;
  text-align: center;
  padding-right: 10px;
  width: 150px;
  text-decoration: none;
}

.ListItem a:hover {
  text-decoration: underline;
  color: var(--yellow);
  cursor: pointer;
  font-weight: 900;
}

.Reporte {
  width: 100vw;
  height: 70%;
  background-color: var(--yellow);
  align-items: center;
  justify-content: center;
}

.Reporte h1 {
  font-size: large;
  font-weight: bold;
  color: black;
  text-align: center;
  padding-left: 20px;
}

.Reporte button {
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
  border-radius: 10px;
  background-color: var(--yellow);
  border: 2px solid black;
  box-sizing: border-box;
  font-size: x-large;
}

.Reporte button:hover {
  cursor: pointer;
}

.ReporteDate {
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  width: 100% !important;
  background-color: white;
}

.ReporteSelect {
  margin-left: 100px;
  width: 20% !important;
  font-size: medium;
  font-weight: bold;
}