:root {
  --yellow: #FFE900;
  --gris: #808080;
}

.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10vh 80vh 10vh;
  background-color: white;
  height: 100vh;
  width: 100vw;
  align-items: center;
}

.Header {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: var(--gris);
  align-items: center;
  justify-content: space-evenly;
}

.Header img {
  max-height: 80%;
}

.Header h1 {
  font-size: xx-large;
  font-weight: bolder;
  color: black;
}

.Footer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--gris);
  align-items: center;
  justify-content: center;
}

.Footer p {
  font-size: small;
  font-weight: bolder;
  color: black;
  height: 25%;
  margin: 0;
}

.Loading {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--gris);
  border-radius: 100%;
  height: 30%;
  width: 30%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.Loading p {
  font-size: xx-large;
  font-weight: bolder;
  color: var(--yellow);
  text-align: center;
}

.Login {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--yellow);
  border-radius: 20px;
  height: 40%;
  width: 30%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.Login input {
  width: 70%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  font-size: large;
  font-weight: bold;
}

.Login button {
  width: 25%;
  border-radius: 20px;
  padding: 2%;
  margin: 3%;
  background-color: black;
  color: var(--yellow);
  font-size: large;
  font-weight: bold;
}

.Login button:hover {
  cursor: pointer;
}